import React from 'react'

import SEO from '../components/seo'
import NotFoundView from '../views/404'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <NotFoundView />
  </>
)

export default NotFoundPage
